<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editProduct">
      <b-form
        v-if="productPrepare"
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">
              {{ $t('Detalles del Producto') }}
            </h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="product-name"
              >
                <b-form-input
                  v-model="product.name"
                  name="name"
                  :readonly="role === 'admin_empresa'"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Categoría')"
              rules="required"
            >
              <b-form-group
                :label="$t('Categoría')"
                label-for="product-category"
              >
                  <SelectCategories v-model="category" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--<b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Revisión obligatoria')"
            >
              <b-form-group
                :label="$t('Revisión obligatoria')"
                label-for="product-revision-obligatoria"
              >
                <b-form-checkbox
                  id="revision-obligatoria"
                  v-model="revisionObligatoria"
                  name="check-button-revision-obligatoria"
                  switch
                  class="mt-1"
                  inline
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Revisión periódica')"
            >
              <b-form-group
                :label="$t('Revisión periódica')"
                label-for="product-revision-periodica"
              >
                <b-form-checkbox
                  id="revision-periodica"
                  v-model="revisionPeriodica"
                  :disabled="revisionPeriodicaDisabled"
                  name="check-button-revision-periodica"
                  switch
                  class="mt-1"
                  inline
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="revisionPeriodica"
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Frecuencia revisiones')"
              :rules="revisionPeriodicaRequired"
            >
              <b-form-group
                :label="$t('Frecuencia revisiones')"
                label-for="product-frequency"
              >
                <v-select
                  v-model="frecuencia"
                  :filterable="true"
                  :searchable="true"
                  :options="selectRevisiones"
                  :placeholder="$t('Frecuencia revisiones')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
           -->
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="product-description"
              >
                <quill-editor
                  v-model="product.description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Imagen')"
              label-for="product-image"
            >
              <ImageDropzone
                v-if="productPrepare"
                ref="image"
                :files="imagen"
                max-files="1"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Documentos')"
              label-for="documents"
            >
              <ImageDropzone
                ref="documents"
                :files="documents"
                max-files="4"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Guardar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import router from '@/router'
import SelectCategories from "@/components/selectCategories/SelectCategories.vue";

export default {
  components: {
      SelectCategories,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      documents: [],
      imagen: [],
      productPrepare: false,
      category: '',
      revisionObligatoria: false,
      revisionPeriodica: false,
      revisionPeriodicaDisabled: false,
      frecuencia: false,
      revisionPeriodicaRequired: '',
      selectRevisiones: [
        { value: '3', label: this.$t('trimestral') },
        { value: '6', label: this.$t('semestral') },
        { value: '12', label: this.$t('anual') },
      ],
    }
  },
  watch: {
    revisionObligatoria() {
      if (this.revisionObligatoria) {
        this.revisionPeriodica = true
        this.revisionPeriodicaDisabled = true
      } else {
        this.revisionPeriodicaDisabled = false
      }
    },
    revisionPeriodica() {
      if (this.revisionPeriodica) {
        this.revisionPeriodicaRequired = 'required'
      } else {
        this.revisionPeriodicaRequired = ''
      }
    },
  },
  computed: {
    ...mapGetters({
      localeDatePicker: 'languages/getLocaleDatePicker',
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      product: 'products/getProduct',
      role: 'auth/getRole',
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'products/edit',
      getProduct: 'products/getProduct',

    }),
    handleSubmit() {
      this.$refs.editProduct.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id: this.product.id, product: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.image.getFormData('imagen')

      this.$refs.documents.getFormData('documents', data)

      data.append('name', this.product.name)

      if (!this.revisionObligatoria && !this.revisionPeriodica) {
        data.append('type_review_id', 5)
      } else if (this.revisionObligatoria) {
        if (this.frecuencia.value == 12) {
          data.append('type_review_id', 1)
        } else if (this.frecuencia.value == 6) {
          data.append('type_review_id', 3)
        } else if (this.frecuencia.value == 3) {
          data.append('type_review_id', 6)
        }
      } else if (this.revisionPeriodica) {
        if (this.frecuencia.value == 12) {
          data.append('type_review_id', 2)
        } else if (this.frecuencia.value == 6) {
          data.append('type_review_id', 4)
        } else if (this.frecuencia.value == 3) {
          data.append('type_review_id', 7)
        }
      }
      data.append('modelo', this.product.modelo)
      data.append('fabricante', this.product.fabricante)
      data.append('description', this.product.description)

      data.append('category_id', this.category.id)

      return data
    },
    createFile(object, image, type) {
      let file = ''
      if (object) {
        file = {
          name: object.name,
          url: object.path,
          urlDelete: `/products/delete_docs/${this.product.id}/${type}`,
        }
      } else if (image !== null) {
        let name = ''
        if (typeof image === 'string') {
          name = image.split('/')
        }
        file = {
          name: name[name.length - 1],
          url: image,
          urlDelete: `/products/delete_docs/${this.product.id}/${type}`,
        }
      }
      if (file !== '') {
        this[type].push(file)
      }
    },
    setData() {
      this.createFile(this.product.imageObject, this.product.imagen, 'imagen')

      if (this.product.category) {
        this.category = this.product.category
      }

      if (this.product.type_review) {
        if (this.product.type_review.tiempoMeses > 0) {
          this.selectRevisiones.forEach(element => {
            if (element.value == this.product.type_review.tiempoMeses) {
              this.frecuencia = element
            }
          })
        }
        if (this.product.type_review.obligatoria) {
          this.revisionObligatoria = true
        }
        if (this.product.type_review.periodica) {
          this.revisionPeriodica = true
        }
      }

      if (this.product.documents.length > 0) {
        this.product.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/tasks/delete_doc/${this.product.id}/${element.id}`,
          };
          this.documents.push(file);
        });
      }

      let ifedit = false
      if ((this.role === 'admin_cliente' && this.product.client_id === this.user.clients[0].id)
          || (this.role === 'admin_empresa' && this.product.client_id === null)
          || this.role === 'super_admin') {
        ifedit = true
      } else {
        router.push({ name: 'viewProduct', params: { id: this.product.id } })
      }

      if (ifedit) {
        this.productPrepare = true
      }
    },
  },
  async created() {
    await this.getProduct(this.$route.params.id)
    await this.setData()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
